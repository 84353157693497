/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import classNames from 'classnames';
import Utils from 'formiojs/utils';
import i18next from 'i18next';
import React, { useMemo, useRef } from 'react';
import { Form } from 'react-formio';
import { useTranslation } from 'react-i18next';
import { getCustomEventHandler } from 'src/components/FormioForm/FormioCustomEventHandlersMap';
import { getFormioDOMEventHandler } from 'src/components/FormioForm/FormioDOMEventHandlersMap';
import { FormioFormProps } from 'src/components/FormioForm/FormioFormProps';
import { FormNormalizer } from 'src/components/FormioForm/FormNormalizer';
import { sanitizeConfig } from 'src/components/FormioForm/sanitizeConfig';
import { SubmissionNormalizer } from 'src/components/FormioForm/SubmissionNormalizer';
import { ImageGalleryWrapper } from 'src/components/ImageGalleryWrapper/ImageGalleryWrapper';
import { FormioCustomEvent } from 'src/core/Formio.types';
import { useAutoSubmitFormioIframe } from 'src/hooks/useAutoSubmitFormioIframe';
import { useIllion } from 'src/hooks/useIllion';
import { useJumio } from 'src/hooks/useJumio';
import { usePandaDoc } from 'src/hooks/usePandaDoc';
import { usePlaid } from 'src/hooks/usePlaid';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function FormioForm<T = any>({
    readOnly = false,
    normalizeSubmission,
    form,
    ...props
}: FormioFormProps<T>) {
    const { submission, onSubmit, onCustomEvent, getForm } = props;
    const { i18n } = useTranslation();
    const options = useMemo(() => {
        return {
            readOnly,
            ...sanitizeConfig,
            i18next,
            language: i18n.language,
            evalContext: {
                utils: Utils, // override utils
            },
        };
    }, [readOnly, i18n.language]);

    const formInstance = useRef<any>();
    const containerRef = useRef<any>();

    const parsedDataFromUrl = UrlHelper.getParsedSearchQuery();

    useJumio(formInstance);
    usePandaDoc(formInstance);
    useAutoSubmitFormioIframe(formInstance);
    useIllion(formInstance);
    usePlaid(formInstance);

    const propsWithTransformedSubmission = {
        ...props,
        submission: {
            ...props.submission,
            data: {
                ...props.submission?.data,
                ...parsedDataFromUrl,
                env: SettingsStore.settings?.env,
            },
        },
    };

    // eslint-disable-next-line sonarjs/prefer-immediate-return, sonarjs/cognitive-complexity
    const renderedForm = useMemo(() => {
        if (!form) {
            return null;
        }

        return (
            <Form
                {...propsWithTransformedSubmission}
                form={FormNormalizer.normalize(form, readOnly)}
                ref={(form: any) => {
                    formInstance.current = form;
                    getForm && getForm(form);
                }}
                options={options}
                onSubmit={() => {
                    const data = formInstance.current?.formio?._data;
                    const result = { data };

                    if (normalizeSubmission) {
                        result.data = result.data
                            ? SubmissionNormalizer.normalize(
                                  formInstance.current,
                                  result.data,
                              )
                            : {};
                    }

                    onSubmit && onSubmit(result);
                }}
                onCustomEvent={(event: FormioCustomEvent<any>) => {
                    const defaultCustomEventHandler = getCustomEventHandler(
                        event.type,
                    );
                    if (onCustomEvent) {
                        onCustomEvent(event);
                    }
                    if (defaultCustomEventHandler) {
                        defaultCustomEventHandler(event, { form, ...props });
                    }
                }}
            />
        );
    }, [form, submission, onSubmit, onCustomEvent, options]);

    return (
        <StyledFormWrapper
            onClick={(e: React.MouseEvent<HTMLHRElement>) => {
                const customBehavior = getFormioDOMEventHandler(e.type);
                if (customBehavior) {
                    customBehavior(e);
                }
            }}
            ref={containerRef}
            key={i18n.language}
        >
            {renderedForm}
            <ImageGalleryWrapper containerRef={containerRef} />
        </StyledFormWrapper>
    );
}

const StyledFormWrapper = styled.div.attrs((props) => {
    return {
        ...props,
        className: classNames(props.className, 'formio-container'),
    };
})`
    padding-bottom: 70px;

    .radio-selected {
        pointer-events: none;
    }

    *:not(.formio-compoenent-form) .alert {
        display: none;
    }
`;
