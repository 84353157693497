export const CssVariablesTheme = {
    // Header
    headerColor: '#8b959e',
    headerBackground: '#ffffff',
    headerApplyBtnBg: 'transparent',
    headerApplyBtnBorderColor: '#8c002f',
    headerApplyBtnColor: '#000000',
    headerFontSize: '16px',
    headerActiveLinkColor: '#000000',
    logoMaxSize: '100%',

    /* Body*/
    formTextColor: '',
    background: '#ffffff',
    font: 'SF Pro Text',
    fontSize: '16px',
    itemListBg: '#f6f7f8',
    basePageWidth: '',
    basePageMaxWidth: '',

    /* Form*/
    labelColor: '#97a7b8',
    inputBorderColor: '#DADEE2',
    inputBg: '#ffffff',
    inputBorderColorHover: '#8c002f',
    inputBorderRadius: '8px',
    formButtonsBg: '#8c002f',
    formButtonsColor: '#ffffff',
    tabsColor: '#a9afb7',
    calculatorSecondaryText: '#A9AFB7',
    calculatorSliderTrack: '#8c002f',
    calculatorInputColor: '#000000',
    calculatorSliderHandleBg: '#8c002f',
    calculatorSliderHandleBorder: '#8c002f',
    tabsBorderBottomColor: 'transparent',
    activeTabColor: '#8c002f',
    activeTabBorderColor: '#8c002f',

    /* Footer*/
    footerBg: '#ffffff',
    footerTextColor: '#97a8b8',

    /* Statuses color */
    pending: {
        color: '#000000',
        borderColor: '#9B9BA4',
        backgroundColor: '#9B9BA4',
    },
    active: {
        color: '#000000',
        borderColor: '#3FE579',
        backgroundColor: '#3FE579',
    },
    repaid: {
        color: '#000000',
        borderColor: '#9B9BA4',
        backgroundColor: '#9B9BA4',
    },
    overdue: {
        color: '#000000',
        borderColor: '#F33F4A',
        backgroundColor: '#F33F4A',
    },
    writtenOff: {
        color: '#000000',
        borderColor: '#9B9BA4',
        backgroundColor: '#9B9BA4',
    },
    processing: {
        color: '#000000',
        borderColor: '#E3DB40',
        backgroundColor: '#E3DB40',
    },
    error: {
        color: '#000000',
        borderColor: '#F33F4A',
        backgroundColor: '#F33F4A',
    },
    onKyc: {
        color: '#000000',
        borderColor: '$9B9BA4',
        backgroundColor: '#9B9BA4',
    },
    verification: {
        color: '#000000',
        borderColor: '#9B9BA4',
        backgroundColor: '#9B9BA4',
    },
    updateRequested: {
        color: '#000000',
        borderColor: '#9B9BA4',
        backgroundColor: '#9B9BA4',
    },
    underwriting: {
        color: '#000000',
        borderColor: '#9B9BA4',
        backgroundColor: '#9B9BA4',
    },
    declined: {
        color: '#000000',
        borderColor: '#F33F4A',
        backgroundColor: '#F33F4A',
    },
    canceled: {
        color: '#000000',
        borderColor: '#F33F4A',
        backgroundColor: '#F33F4A',
    },
    approved: {
        color: '#000000',
        borderColor: '#3FE579',
        backgroundColor: '#3FE579',
    },
    disbursed: {
        color: '#000000',
        borderColor: '#3FE579',
        backgroundColor: '#3FE579',
    },

    isFilledStatus: false,
};
